import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';

class IndexPage extends React.Component {
  render() {
    return (
      <div>
        <SEO
          title="Color Management in Avito Design System"
          keywords={['blog', 'gatsby', 'javascript', 'react']}
        />
        <h1>Color Management in Avito Design System</h1>
        <Section>
          <p style={{ opacity: '0.66' }}>
            product design ・ UX research ・ design ops
          </p>
          <p>
            During my first year at Avito, I was responsible for the web part of
            the Avito design system: React components library and UI Kit to be
            used by our product teams.
          </p>
          <p>
            We’ve made a lot of iterations and tried to improve the developer
            and designer experience in many ways from migrating from Sketch to
            Figma to introducing a series of design workshops where we worked
            out guidelines for the most common components and UI patterns.
          </p>
          <p>
            One of our team’s initiatives was improving the developer experience
            (DX) when working with Avito’s color palette. It went quite well and
            I’ve made a whole talk about this project at Avito Design Meetup (
            <a href="https://www.youtube.com/watch?v=D37MEvB1bn0">
              video in Russian
            </a>
            ) with 180+ audience.
          </p>
        </Section>
        <Section>
          <h2>Team</h2>
          <p>Design Manager: Alexander Stogov</p>
          <p>Tech Lead: Ekaterina Petrova</p>
          <p>Development: Alexandra Shalamova</p>
        </Section>
        <Section>
          <h2>My Role</h2>
          <p>
            I was a single designer on the Web Design System (WDS) team
            responsible for desktop components. I worked closely with developers
            inside and outside the DS team. My experience as a developer helped
            me communicate clearer with both user groups: devs and designers.
          </p>
        </Section>
        <Section>
          <h2>Business Goals and Challenges</h2>
          <p>
            In this particular case, our main metric was time to market. Avito
            has two dozen teams responsible for multiple parts of the product
            experience, and all of them strive to ship product updates on time
            and with the least possible number of bugs.
          </p>
          <ol>
            <li>
              We wanted to reduce time to market for product teams, thus saving
              a bunch of operational costs for the company;
            </li>
            <li>
              This could be done via two main routes: giving the dev teams a
              simple tool to work with production components in a correct way
              and double-check themselves...
            </li>
            <li>
              ...and, secondly, allowing designers to communicate with devs
              across the company in a very clear way by giving them design
              guidelines for different parts of Avito’s design system.
            </li>
          </ol>
          <p>
            As a relatively old business, Avito’s codebase evolution was not
            always systematic. Hence, we were facing different code styles,
            different code conventions, and technologies all mixed up. This, in
            turn, led to a lot of different ways colors were represented in
            code.
          </p>
          <p>
            WDS looked at the data, interviewed several devs from different
            teams, and saw an opportunity to save precious time for all parties.
          </p>
          <p>
            Avito has a palette of approximately 40 colors at the time, and yet
            we saw more than 2000 color variations in code, plus, about 8000
            instances of these two thousand colors were scattered historically
            in production code.
          </p>
          <p>
            Developers were wasting time trying to figure out what is the right
            way to use Avito colors, which colors were experimental and which
            were just plain obsolete. Designers also needed an opportunity to
            see which colors are common in existing Avito, and which should not
            be used due to their overall scarcity.
          </p>
          <p>
            This desperately needed to be fixed. And we’ve made a tool just for
            that.
          </p>
        </Section>
        <Section>
          <h2>Testing and Making the Adjustments</h2>
          <p>
            First, we’ve made a web app crawling through all the repos and
            analyzing the colors in the code.
          </p>
          <p>
            It showed statistics of color usage, and in case the particular
            color was not in our palette, showed the closest “correct” color
            from the brand palette. The process of figuring out the criteria of
            what is a close color match and what’s not was quite a challenge in
            itself. Finally, we’ve achieved a good result but decided that in
            controversial cases designers can choose the most fitting color
            manually.
          </p>
          <p>
            One of the app killer features was that it was able to create code
            patches on the go for the specific part of code. Then, this patch
            could be easily handed over to the product team developer
            responsible for the code directly.
          </p>
          <p>
            We’ve understood, that not all colors should be fixed: some of them
            were not matching color palette intensionally, i.e. colors in svg
            illustrations or colors in some promotional webpages. So, we
            excluded those parts of the codebase.
          </p>
        </Section>
        <Section>
          <h2>Results</h2>
          <p>
            WDS team made a huge effort and delivered a tool allowing to save
            time for both designers and developers.
          </p>
          <p>
            This tool showed the usage stats for all the colors in the Avito
            website production code.
          </p>
          <p>
            It also was able to generate code patches to fix “rogue” colors
            semi-automatically by devs in the product teams.
          </p>
          <p>
            Designer and developer experiences were improved dramatically, and the
            sheer number of legacy colors was reduced significantly (to
            approximately 400 in promotional materials or illustrations).
          </p>
          <p>
            Web Design System team worked out the guides for color usage in
            collaboration with other product designers in a series of workshops.
          </p>
          <p>
            An important part of every designer’s professional work is knowledge
            sharing, so we’ve prepared a public talk for the Avito Design Meetup
            about our interim results.
          </p>
        </Section>
      </div>
    );
  }
}

const Section = styled.section`
  padding: 0;
  p {
    color: #000;
  }

  &:first-child {
    margin: 8rem 0 2rem;
  }

  &.typeA,
  &.typeB,
  &.typeC {
    border-radius: 0.5rem;
    padding: 1rem;
  }

  &.typeA {
    background: #ffd84d;
  }

  &.typeA p,
  &.typeB p,
  &.typeC p {
    color: #000;
  }

  & > img,
  & > iframe {
    width: 100%;
    margin: 4rem 0;
  }

  h2 {
    text-align: left;
    color: #000;
  }

  &.secondaryHolder {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  & .secondary {
    border-radius: 0.5rem;
    padding: 1.5rem;
    background: rgba(232, 233, 239, 1);
    // background: #FFD84D;
    width: 28rem;
  }

  @media (max-width: 1024px) {
    &.secondaryHolder {
      flex-direction: column;
    }

    & .secondary {
      width: 100%;
      margin: 0 0 3rem;
    }
  }

  .secondary h2,
  .secondary p {
    text-align: left;
    color: #000;
    padding: 0;
  }

  .secondary a {
    color: #000;
    text-decoration: none;
    border-bottom: 4px solid rgba(0, 0, 0, 0.75);
  }

  .secondary a:hover {
    border-bottom: 4px solid #ffd84d;
  }
`;

export default IndexPage;
